import React, { useState,useEffect,useRef } from 'react';
import { Row, Col } from "react-bootstrap";
import { AnimatePresence,motion } from 'framer-motion';
import '../lib/bootstrap.min.css'
import './Main.css';

const Transport = (props) => {

    const ref = useRef(null);
    const [height,setHeight] = useState(0)
    const [width,setWidth] = useState(0)

    useEffect(() => {
        setHeight(ref.current.clientHeight);
        setWidth(ref.current.clientWidth);
    }, []);

  return (
    <AnimatePresence>
        <Row>
            <Col md={12}>
                <motion.div 
                    ref={ref}
                    className='content-transport'
                    style={{height:(props.height)+'px', padding:'0px'}}
                    initial={{ opacity: 0, marginTop:-50 }}
                    animate={{ opacity: 1, marginTop: 0,  }}
                    exit={{opacity:0, marginTop:300}}
                    transition={{ duration: 1.2, ease: [0.87, 0, 0.13, 1] }}
                >
                    <Row>
                        <Col md={9} className='ct-left' style={{height:(props.height)+'px',padding:'0px'}}>
                            <motion.div style={{margin:'0px', width:'100%'}}>
                                    <div className='carName'>
                                        <h2>Toyota</h2>
                                        <h3>Avanza</h3>
                                        <p>6 ADULT / FULL AC / PREMIUM</p>
                                    </div>
                                    <div className='carSpec'>
                                        <h2>Rp. <span>2.450</span>.000</h2>
                                        <p>12 Hours - Inner City <br /> Included Driver and Petrol <br /> Overtime 10% / hour</p>
                                        <button className='btnCarOrder'>Order Now!</button>
                                    </div>
                                    <div className='carPhoto'>
                                <img src='images/avanza.png' width={'100%'} /></div>
                            </motion.div>
                        </Col>
                        <Col md={3} style={{height:(props.height)+'px', overflow:'scroll', padding:'0px'}}>
                            <motion.div style={{margin:'0px', width:'100%'}}>
                                <button
                                    className='car-thumb'
                                >
                                    <h3><span>Toyota</span> Alphard</h3>
                                    <h4>6 Adult / Full Ac / Premium</h4>
                                    <div><img src='images/avanza.png'  /></div>
                                </button>
                            </motion.div>
                            <motion.div>
                                 <button
                                    className='car-thumb'
                                >
                                    <h3><span>Toyota</span> Alphard</h3>
                                    <h4>6 Adult / Full Ac / Premium</h4>
                                    <div><img src='images/avanza.png'  /></div>
                                </button>
                            </motion.div>
                            <motion.div>
                                <button
                                    className='car-thumb'
                                >
                                    <h3><span>Toyota</span> Alphard</h3>
                                    <h4>6 Adult / Full Ac / Premium</h4>
                                    <div><img src='images/avanza.png' /></div>
                                </button>
                            </motion.div>
                            <motion.div>
                                <button
                                    className='car-thumb'
                                >
                                    <h3><span>Toyota</span> Alphard</h3>
                                    <h4>6 Adult / Full Ac / Premium</h4>
                                    <div><img src='images/avanza.png'  /></div>
                                </button>
                            </motion.div>
                            <motion.div>
                                <button
                                    className='car-thumb'
                                >
                                    <h3><span>Toyota</span> Alphard</h3>
                                    <h4>6 Adult / Full Ac / Premium</h4>
                                    <div><img src='images/avanza.png'  /></div>
                                </button>
                            </motion.div>
                            <motion.div>
                                <button
                                    className='car-thumb'
                                >
                                    <h3><span>Toyota</span> Alphard</h3>
                                    <h4>6 Adult / Full Ac / Premium</h4>
                                    <div><img src='images/avanza.png'  /></div>
                                </button>
                            </motion.div>
                        </Col>
                    </Row>
                </motion.div>
            </Col>
        </Row>
    </AnimatePresence>
  )};

export default Transport;


