import React, { useState,useEffect,useRef } from 'react';

import { Row, Col } from "react-bootstrap";
import { AnimatePresence,motion } from 'framer-motion';
import '../lib/bootstrap.min.css'
import './Main.css';

const Home = () => {
  return (
    <AnimatePresence>
        <Row>
            <Col md={6}>
                <motion.div 
                    className='content-left'
                    initial={{ opacity: 0, marginLeft:-300 }}
                    animate={{ opacity: 1, marginLeft: 0,  }}
                    exit={{opacity:0, marginLeft: -300}}
                    transition={{ duration: 0.8, ease: [0.7, 0, 0.3, 1] }}
                >
                    <h2>Hello Mr. Jhon,</h2>
                    <p>Welcome to Aishwarya Hotel, Your room number is <span>503</span> adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip </p>
                    <br />
                    
                </motion.div>
            </Col>
            <Col md={6}>
                <motion.div className='content-right'
                    initial={{ opacity: 0, marginRight:-300 }}
                    animate={{ opacity: 1, marginRight: 0,  }}
                    exit={{opacity:0, marginRight:-300}}
                    transition={{ duration: 0.8, ease: [0.87, 0, 0.13, 1] }}>
                    <div style={{marginTop:'0px'}}>&nbsp;</div>
                    <button className='bbutton'>Property Information &raquo;</button>
                </motion.div>
            </Col>
        </Row>
    </AnimatePresence>
  )};

export default Home;


