import React, { useState,useEffect,useRef } from 'react';

import Clock from 'react-live-clock';
import { Row, Col } from "react-bootstrap";
import { AnimatePresence,motion } from 'framer-motion';
import '../lib/bootstrap.min.css'
import './Main.css';

const ClockWidget = () => {
  return (
    <AnimatePresence>
        <Row>
            <Col md={12}>
                <motion.div 
                    className='content-left'
                    initial={{ opacity: 0, marginTop:-100 }}
                    animate={{ opacity: 1, marginTop: 0,  }}
                    exit={{opacity:0, marginTop:300}}
                    transition={{ duration: 1.2, ease: [0.87, 0, 0.13, 1] }}
                >
                    <h2>Flight,</h2>
                    <Clock format={'HH:mm:ss'} ticking={true} timezone={'US/Pacific'} />
                    
                </motion.div>
            </Col>
        </Row>
    </AnimatePresence>
  )};

export default ClockWidget;


