import React, { useState,useEffect,useRef } from 'react';
import { Row, Col } from "react-bootstrap";
import { AnimatePresence,motion } from 'framer-motion';
import Datex from '@drumtj/datex';
import Home from './Home'
import Flight from './Flight';
import Transport from './Transport';
import '../lib/bootstrap.min.css'
import './Main.css';
import Fnb from './Fnb';
import Clock from './ClockWidget';
import Helpdesk from './Helpdesk';

const Main = () => {
    const [hWindow, setHWindow] = useState(0);
    const [hHeader, setHHeader] = useState(110);
    const [hContent, setHContent] = useState(0);
    const [hFooter, setHFooter] = useState(80);
    const [isPage, setIsPage] = useState('home');

    const [isHome,setIsHome] = useState(true)
    const [isFlight,setIsFlight] = useState(false)

    const [weatherLoading, setWeatherLoading] = useState(false);
    const [weather,setWeather] = React.useState({
        lat: '-8.6610792',
        lon: '115.1872422',
        key:'0f1903d6af398d812da36080b1c80065',
        unit:'metric'
      })
    const ref = useRef(null);
    const [height,setHeight] = useState(0)
    const [width,setWidth] = useState(0)
    const getWeather = () =>{
        setWeatherLoading(true)
        const myHeaders = new Headers();
        const urlencoded = new URLSearchParams();
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch('https://api.openweathermap.org/data/2.5/weather?lat='+weather.lat+'&lon='+weather.lon+'&appid='+weather.key+'&units='+weather.unit, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                document.getElementById("weather_city").innerHTML = result.name+`<br />`+ new Datex(Date.now()).format("DDD, DD MMM YYYY")
                document.getElementById("weather_time").innerHTML = new Datex(Date.now()).format("hh:mm")
                document.getElementById("weather_icon").innerHTML = `
                <img src='https://rodrigokamada.github.io/openweathermap/images/`+result.weather[0].icon + `_t@2x.png' height='80' alt='weather' />`
                document.getElementById("weather_temp").innerHTML = parseInt(result.main.temp)+`<sup> o</sup>`
                
                setWeatherLoading(false)
            })
            .then((error)=>{
                //console.log(error)
                setWeatherLoading(false)
            })
    }

    useEffect(() => {
        getWeather()
        setHeight(ref.current.clientHeight);
        setWidth(ref.current.clientWidth);
        setHWindow(ref.current.clientHeight);
        setHContent((ref.current.clientHeight)-(hHeader+hFooter));

        document.getElementById("header").style.height = hHeader + "px";
        document.getElementById("content").style.height = ((ref.current.clientHeight)-(hHeader+hFooter))+ "px";
        document.getElementById("footer").style.height = hFooter+ "px";
        const interval = setInterval(() => {
            //getWeather()
        }, 60000);
    }, []);


  return (
    <>
        <style>{`
            .tv{
                background:linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('https://last40.com/backgroundtv.jpg') no-repeat;
                background-size: cover;
            }
            .header{
                height: `+hHeader+`px;
            }
            .content{
                height: `+hContent+`px;
            }
            .footer{
                height: `+hFooter+`px;
            }
        `}</style>
        <div className='tv' id='tv' ref={ref}>
            <Row className='header' id='header'>
                <Col md={7}>
                    <div className='header-left'>      
                        <div className="logo"><img src='https://www.aishwarya-international.com/wp-content/uploads/2023/01/Untitled_Artwork-2.png' alt='logo' /></div>
                    </div>
                </Col>
                <Col md={5}>
                    <div className='header-right'>
                        <div className='weather' id='weather'>
                            <div id='weather_city'></div>
                            <div id='weather_time'></div>
                            <div id='weather_icon'></div>
                            <div id='weather_temp'></div>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className='content' id='content'>
                {isPage === 'home' && (<Home />)}
                {isPage === 'flight' && (<Flight />)}
                {isPage === 'transport' && (<Transport height={hContent} />)}
                {isPage === 'fnb' && (<Fnb height={hContent} />)}
                {isPage === 'helpdesk' && (<Helpdesk height={hContent} />)}
                {isPage === 'clock' && (<Clock height={hContent} />)}
            </div>
            <Row className='footer' id='footer'>
                <Col md={12}>
                    <button className='bmenu' onClick={()=>{ 
                        setIsPage('home')
                    }}>
                        <img src='https://last40.com/uitv/home.png' alt='Property' height={27} />
                    </button>
                    <button className='bmenu'>
                        <img src='https://last40.com/uitv/tv.png' alt='Tv Channel' height={28} />
                    </button>
                    <button className='bmenu' onClick={()=>{window.ReactNativeWebView.postMessage("openYoutube")}}>
                        <img src='https://last40.com/uitv/youtube.png' alt='Youtube' height={24} />
                    </button>
                    <button className='bmenu' onClick={()=>{window.ReactNativeWebView.postMessage("openNetflix")}}>
                        <img src='https://last40.com/uitv/netflix.png' alt='Netflix' height={17} />
                    </button>
                    <button className='bmenu' onClick={()=>{ 
                        setIsPage('home')
                    }}>
                        <img src='https://last40.com/uitv/prime-video.png' alt='Prime Video' height={32} />
                    </button>
                    <button className='bmenu' onClick={()=>{window.ReactNativeWebView.postMessage("openNetflix")}}>
                        <img src='https://last40.com/uitv/vidio.png' alt='Vidio' height={22} />
                    </button>
                    <button className='bmenu' onClick={()=>{window.ReactNativeWebView.postMessage("openNetflix")}}>
                        <img src='https://last40.com/uitv/vision-plus.png' alt='Vision Plus' height={20} />
                    </button>
                    <button className='bmenu'>
                        <img src='https://last40.com/uitv/disney.png' alt='Disney +' height={32} />
                    </button>
                    <button className='bmenu' onClick={()=>{ setIsPage('flight')}}>
                        <img src='https://last40.com/uitv/flight.png' alt='Flight Schedule' height={32} />
                    </button>
                    <button className='bmenu' onClick={()=>{ setIsPage('transport')}}>
                        <img src='https://last40.com/uitv/transport.png' alt='Transport' height={26} />
                    </button>
                    <button className='bmenu' onClick={()=>{ setIsPage('fnb')}}>
                        <img src='https://last40.com/uitv/fnb.png' alt='Food and Beverage' height={32} />
                    </button>
                    <button className='bmenu' onClick={()=>{ setIsPage('helpdesk')}}>
                        <img src='https://last40.com/uitv/helpdesk.png' alt='Helpdesk' height={32} />
                    </button>
                    <button className='bmenu' onClick={()=>{ setIsPage('clock')}}>
                        <img src='https://last40.com/uitv/wifi.png' alt='Wifi' height={32} />
                    </button>
                    <button className='bmenu'>
                        <img src='https://last40.com/uitv/wake-up.png' alt='Wifi' height={32} />
                    </button>
                    
                </Col>
            </Row>
        </div>
    </>
  );
};

export default Main;


