import React, { useState,useEffect,useRef } from 'react';
import { Row, Col } from "react-bootstrap";
import { AnimatePresence,color,motion } from 'framer-motion';
import '../lib/bootstrap.min.css'
import './Main.css';
import './Helpdesk.css';

const Helpdesk = (props) => {

    const ref = useRef(null);
    const [height,setHeight] = useState(0)
    const [width,setWidth] = useState(0)

    useEffect(() => {
        setHeight(ref.current.clientHeight);
        setWidth(ref.current.clientWidth);
    }, []);

  return (
    <AnimatePresence>
        <Row>
            <Col md={12}>
                <motion.div 
                    ref={ref}
                    className='content-helpdesk'
                    style={{height:(props.height)+'px', padding:'0px'}}
                    initial={{ opacity: 0, marginTop:-50 }}
                    animate={{ opacity: 1, marginTop: 0,  }}
                    exit={{opacity:0, marginTop:300}}
                    transition={{ duration: 1.2, ease: [0.87, 0, 0.13, 1] }}
                >
                    <Row>
                        <Col md={3} className='ch-left' style={{height:(props.height)+'px'}}>
                            <motion.div style={{margin:'0px', width:'100%'}}>
                                <div className='helpPhoto'>
                                    <img src='images/helpdesk.png' /></div>
                                <div className='helpTitle'>
                                    <h2>Helpdesk</h2>
                                    <h3>Dashboard</h3>
                                </div>
                            </motion.div>
                        </Col>
                        <Col md={9} style={{height:(props.height)+'px', overflow:'scroll', padding:'0px'}}>
                            <motion.div style={{margin:'20px', padding:'40px',  backgroundColor:'#111111'}}>
                                <table className="tableHelpdesk" width={'100%'}>
                                    <thead>
                                        <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Request / Order</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Time</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>11/06 10:22</td>
                                            <td>Car Rental - Toyota Avanza</td>
                                            <td>John Stallman</td>
                                            <td>00:20</td>
                                            <td>On Proggress</td>
                                            <td> &raquo; </td>
                                        </tr>
                                        <tr>
                                            <td>11/06 10:22</td>
                                            <td>Car Rental - Toyota Avanza</td>
                                            <td>John Stallman</td>
                                            <td>00:20</td>
                                            <td>On Proggress</td>
                                            <td> &raquo; </td>
                                        </tr>
                                        <tr>
                                            <td>11/06 10:22</td>
                                            <td>Car Rental - Toyota Avanza</td>
                                            <td>John Stallman</td>
                                            <td>00:20</td>
                                            <td>On Proggress</td>
                                            <td> &raquo; </td>
                                        </tr>
                                        <tr>
                                            <td>11/06 10:22</td>
                                            <td>Car Rental - Toyota Avanza</td>
                                            <td>John Stallman</td>
                                            <td>00:20</td>
                                            <td>On Proggress</td>
                                            <td> &raquo; </td>
                                        </tr>
                                        <tr>
                                            <td>11/06 10:22</td>
                                            <td>Car Rental - Toyota Avanza</td>
                                            <td>John Stallman</td>
                                            <td>00:20</td>
                                            <td>On Proggress</td>
                                            <td> &raquo; </td>
                                        </tr>
                                        <tr>
                                            <td>11/06 10:22</td>
                                            <td>Car Rental - Toyota Avanza</td>
                                            <td>John Stallman</td>
                                            <td>00:20</td>
                                            <td>On Proggress</td>
                                            <td> &raquo; </td>
                                        </tr>
                                        <tr>
                                            <td>11/06 10:22</td>
                                            <td>Car Rental - Toyota Avanza</td>
                                            <td>John Stallman</td>
                                            <td>00:20</td>
                                            <td>On Proggress</td>
                                            <td> &raquo; </td>
                                        </tr>
                                        <tr>
                                            <td>11/06 10:22</td>
                                            <td>Car Rental - Toyota Avanza</td>
                                            <td>John Stallman</td>
                                            <td>00:20</td>
                                            <td>On Proggress</td>
                                            <td> &raquo; </td>
                                        </tr>
                                        <tr>
                                            <td>11/06 10:22</td>
                                            <td>Car Rental - Toyota Avanza</td>
                                            <td>John Stallman</td>
                                            <td>00:20</td>
                                            <td>On Proggress</td>
                                            <td> &raquo; </td>
                                        </tr>
                                        <tr>
                                            <td>11/06 10:22</td>
                                            <td>Car Rental - Toyota Avanza</td>
                                            <td>John Stallman</td>
                                            <td>00:20</td>
                                            <td>On Proggress</td>
                                            <td> &raquo; </td>
                                        </tr>
                                        <tr>
                                            <td>11/06 10:22</td>
                                            <td>Car Rental - Toyota Avanza</td>
                                            <td>John Stallman</td>
                                            <td>00:20</td>
                                            <td>On Proggress</td>
                                            <td> &raquo; </td>
                                        </tr>
                                        <tr>
                                            <td>11/06 10:22</td>
                                            <td>Car Rental - Toyota Avanza</td>
                                            <td>John Stallman</td>
                                            <td>00:20</td>
                                            <td>On Proggress</td>
                                            <td> &raquo; </td>
                                        </tr>
                                    </tbody>
                                    </table>
                            </motion.div>
                            
                        </Col>
                    </Row>
                </motion.div>
            </Col>
        </Row>
    </AnimatePresence>
  )};

export default Helpdesk;


