import React, { useState,useEffect,useRef } from 'react';
import { Row, Col } from "react-bootstrap";
import { AnimatePresence,motion } from 'framer-motion';
import '../lib/bootstrap.min.css'
import './Main.css';

const Fnb = (props) => {

    const ref = useRef(null);
    const [height,setHeight] = useState(0)
    const [width,setWidth] = useState(0)

    useEffect(() => {
        setHeight(ref.current.clientHeight);
        setWidth(ref.current.clientWidth);
    }, []);

  return (
    <AnimatePresence>
        <Row>
            <Col md={12}>
                <motion.div 
                    ref={ref}
                    className='content-fnb'
                    style={{height:(props.height)+'px'}}
                    initial={{ opacity: 0, marginTop:-50 }}
                    animate={{ opacity: 1, marginTop: 0,  }}
                    exit={{opacity:0, marginTop:300}}
                    transition={{ duration: 1.2, ease: [0.87, 0, 0.13, 1] }}
                >
                dimensi area konten (transparant hitam) {width} px x {height} px
                </motion.div>
            </Col>
        </Row>
    </AnimatePresence>
  )};

export default Fnb;


